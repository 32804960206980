<template>
    <div>
        <div>
            <h1 class="cus_title_type_1">应用选择</h1>
            <el-select v-model="applicationId" @change="handleAppChange" style="margin: 10px 0">
                <el-option v-for="item in applications" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
        </div>
        <div>
            <h1 class="cus_title_type_1">权限选择</h1>
            <div style="margin: 20px 0">
                <el-checkbox v-model="checkedAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-tree
                    check-strictly
                    show-checkbox
                    default-expand-all
                    ref="roleTree"
                    node-key="id"
                    :data="treeData"
                    :props="defaultProps"
                    @check="handleCheck"
                >
                </el-tree>
            </div>
        </div>
    </div>
</template>

<script>
import { reqPermission } from '@/api/system/role.js'

export default {
    /* 角色权限 */
    name: 'Permission',

    props: {
        show: { type: Boolean, default: false },
        applications: { type: Array, default: () => [] },
        roleId: { type: Number, default: null },
    },

    data () {
        return {
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            checkedAll: false,
            /** @des 所有选项的id */
            ids: [],
            applicationId: '',
            treeData: []
        }
    },

    watch: {
        show (v) {
            if (v) {
                this.checkedAll = false
            } else {
                this.applicationId = ''
                this.treeData = []
                this.$refs.roleTree.setCheckedKeys([])
            }
        }
    },

    methods: {
        handleCallback (data) {
            this.$emit('callback', data, this.applicationId)
        },

        handleAppChange (id) {
            const { applications, roleId } = this
            this.checkedAll = false
            this.handleCheckAllChange(false)
            this.treeData = applications.find(v => v.id === id).menuTree

            if (roleId) {
                /* 如果有 role id 证明是编辑 */
                reqPermission({ appId: id, roleId })
                    .then(({ data }) => {
                        this.$refs.roleTree.setCheckedKeys(data)
                        this.handleCallback(data)
                    })
            }
        },

        handleCurrentChange (v, a) {
            const data = [...a.checkedKeys]
            this.handleCallback(data)
        },

        handleCheckAllChange (val) {
            const { treeData } = this
            const ids = []
            if (val && treeData.length) {
                const getIds = (arr) => {
                    arr.forEach(v => {
                        ids.push(v.id)
                        if (v.children) {
                            getIds(v.children)
                        }
                    })
                }
                getIds(treeData)
            }
            this.handleCallback(ids)

            this.$refs.roleTree.setCheckedKeys(ids)
        },

        handleCheck (currentNode, treeStatus) {
            /* 选中子节点 */
            const uniteChildSame = (treeList, isSelected) => {
                this.$refs.roleTree.setChecked(treeList.id, isSelected)
                if (treeList.children) {
                    for (let i = 0; i < treeList.children.length; i++) {
                        uniteChildSame(treeList.children[i], isSelected)
                    }
                }
            }
            /* 选中父节点 */
            const selectedParent = (nodeObj) => {
                const node = this.$refs.roleTree.getNode(nodeObj)
                if (node.parent.key) {
                    this.$refs.roleTree.setChecked(node.parent, true)
                    selectedParent(node.parent)
                }
            }
            const selected = treeStatus.checkedKeys.indexOf(currentNode.id)
            if (selected !== -1) {
                /* 处理选中 */
                selectedParent(currentNode)
                uniteChildSame(currentNode, true)
            } else {
                /* 处理取消选中 */
                if (currentNode.children) {
                    uniteChildSame(currentNode, false)
                }
            }

            this.$nextTick(() => {
                const callData = this.$refs.roleTree.getCheckedKeys()
                this.handleCallback(callData)
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
