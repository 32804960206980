<template>
  <div>
    <TableWrap @callback="handlePagination" :total="total">
        <template slot="tablesearch">
            <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
        </template>
        <template slot="tableOperate">
           <el-button type="primary" size="small" @click="handleCreate">新增</el-button>
        </template>
        <el-table :data="tableData" :header-cell-style="{background:'#FAFAFA'}" v-loading="loading">
            <el-table-column prop="roleCode" label="编码"> </el-table-column>
            <el-table-column prop="roleName" label="名称"> </el-table-column>
            <el-table-column prop="roleDesc" label="描述"> </el-table-column>
            <el-table-column label="状态">
                <template v-slot="scope">
                    {{ $utils.getDicValue('ROLE_STATUS', scope.row.delFlag).mean }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="210" align="center">
                <template v-slot="scope">
                    <el-button type="text" size="small" plain @click="handleEdit(scope.row.roleId)">编辑</el-button>
                    <el-button type="text" size="small" plain @click="handleSetPermission(scope.row.roleId)">授权</el-button>
                    <el-button type="text" size="small" plain @click="handleDelete(scope.row.roleId)">
                        <span style="color:#F5222D">
                            删除
                        </span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </TableWrap>

    <el-drawer
        title="授权"
        :visible.sync="permissionVisible"
        direction="rtl"
        :wrapperClosable="false"
        custom-class="cus-drawer"
        @close="handleClose">
        <div class="cus_drawer_content">
            <Permission
                :show="permissionVisible"
                :roleId="currentRoleId"
                :applications="applications"
                @callback="getTreeValue"
            ></Permission>
            <form></form>
            <footer class="cus_drawer_footer">
                <el-button size="small" @click="handleCancelTree">取消</el-button>
                <el-button size="small" type="primary" @click="handleSureTree">确定</el-button>
            </footer>
        </div>
    </el-drawer>

    <el-drawer
        :title="drawTitle"
        :visible.sync="drawer"
        direction="rtl"
        :wrapperClosable="false"
        custom-class="cus-drawer"
        @close="handleClose">
        <!-- <div class="cus_drawer_container"> -->
            <div class="cus_drawer_content">
                <el-form :model="formData" :rules="rules" ref="form" class="real-el-form">
                    <el-form-item label="编码" prop="roleCode">
                        <el-input size="small" v-model="formData.roleCode"></el-input>
                    </el-form-item>
                    <el-form-item label="名称" prop="roleName">
                        <el-input size="small" v-model="formData.roleName"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="delFlag">
                        <el-select size="small" v-model="formData.delFlag" style="width:100%">
                            <el-option v-for="item in statusList" :key="item.val" :label="item.mean" :value="item.val"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述" prop="roleDesc">
                        <el-input size="small" v-model="formData.roleDesc" type="textarea"></el-input>
                    </el-form-item>
                </el-form>
            <!-- </div> -->
            <footer class="cus_drawer_footer">
                <el-button size="small" @click="handleCancel">取消</el-button>
                <el-button size="small" type="primary" @click="handleSure">确定</el-button>
            </footer>
        </div>
    </el-drawer>
  </div>
</template>

<script>
import tableMixIn from '@/mixIn/tableMixIn'
import Permission from './permission'
import { reqApplications } from '@/api/common/common'
import {
    reqRoleList, reqCreateEditRole, reqRoleDetail, reqRoleDelete, reqRolePermissionEdit
} from '@/api/system/role.js'

export default {
    /* 角色管理 */
    name: 'Role',

    components: {
        Permission
    },

    mixins: [tableMixIn],

    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'roleName', label: '名称' }
            ],
            reqTableData: reqRoleList,
            permissionVisible: false,
            appId: '',
            menuList: [],
            treeValue: [],
            currentRoleId: '',
            operateType: 'create',
            drawer: false,
            formData: {
                roleCode: '',
                roleName: '',
                roleDesc: '',
                delFlag: '0'
            },
            rules: {
                roleCode: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                roleName: [{ required: true, message: '请输入内容', trigger: 'blur' }],
            },
            drawTitle: '新增',
            applications: [],
            statusList: []
        }
    },

    created () {
        this.getApplications()
        this.$utils.getDicData('ROLE_STATUS')
            .then((result) => {
                this.statusList = result
                this.getTableData()
            })
    },

    methods: {
        getApplications () {
            reqApplications()
                .then(({ data }) => {
                    this.applications = data
                })
        },

        handleClose () {
            if (this.$refs.form) this.$refs.form.resetFields()
        },

        handleCreate () {
            this.drawTitle = '新增'
            this.operateType = 'create'
            this.drawer = true
        },

        handleCancel () {
            this.drawer = false
        },

        getTreeValue (val, appId) {
            this.treeValue = val
            this.appId = appId
        },

        handleCancelTree () {
            this.permissionVisible = false
        },

        handleEdit (roleId) {
            this.getDetail(roleId)
            this.drawTitle = '编辑'
            this.operateType = 'edit'
            this.drawer = true
        },

        getDetail (id) {
            reqRoleDetail({ id })
                .then(({ data }) => {
                    this.formData = data
                })
        },

        handleSure () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const { operateType: ot, formData } = this
                    let method = 'post'
                    if (ot === 'edit') {
                        method = 'put'
                    }
                    reqCreateEditRole(formData, method)
                        .then(() => {
                            this.getTableData()
                            this.drawer = false
                            this.$message.success('成功')
                        })
                }
            })
        },

        handleSureTree () {
            const { currentRoleId, treeValue, appId } = this

            if (treeValue.length && appId) {
                reqRolePermissionEdit({
                    roleId: currentRoleId,
                    menuIds: treeValue.toString(),
                    appId
                })
                    .then(() => {
                        this.permissionVisible = false
                        this.$message.success('成功')
                    })
            } else {
                this.$message.warning('请选择应用和权限')
            }
        },

        handleSetPermission (id) {
            this.currentRoleId = id
            this.permissionVisible = true
        },

        handleDelete (id) {
            this.$confirm('确认删除?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                reqRoleDelete({ id })
                    .then((result) => {
                        this.$message.success('删除成功!')
                        this.getTableData()
                    })
            })
        },
    }
}
</script>
